<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>3D视觉检测系统</h2>
        <p>系统由高精度3D相机、视觉检测软件、控制器等构成，可搭配各型主流机械臂，利用人工智能技术及图像识别算法对产品进行自动化检测的系统。该系统通过采集产品图像，运用深度学习等相关技术进行图像处理和分析，自动识别出产品的缺陷、瑕疵、污渍等质量问题，并在相应位置标记或分类处理。该系统具有高效、精准、快速、自动化等优点，可大幅度提高产品生产、制造等领域的质量管理和效率水平。</p>
      </div>
      <div class="content">
        <h2>系统特点</h2>
        <p>高精度：系统搭配高精度3D相机结合AI算法实现精度在±0.03mm。</p>
        <p>自动化：利用计算机视觉技术对产品进行全自动检测，无需人工干预，提高了生产效率，降低了成本。</p>
        <p>快速高效：相比传统人工检测，该系统能够在短时间内快速地检测出产品质量问题，从而保证产品品质和交付进度。</p>
        <p>准确性高：采用深度学习算法，具有很强的识别精度和稳定性，准确率高，可以有效避免漏检和误判等问题。</p>
        <p>易操作性：该系统操作简单，只需要少量的培训即可上手操作，无需太多专业技能。</p>
        <p>智能提醒：通过提供可视化报告和实时反馈，快速找出异常，输出报表轻松管理;及时发现并解决问题，方便用户调整生产流程。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>系统软件</h2>
        <img class="img" src="@/assets/img/定位焊接/软件.jpg"/>
      </div>
      <div class="content">
        <h2>控制器</h2>
        <img class="img" src="@/assets/img/视觉检测/控制器.jpg"/>
      </div>
      <div class="content">
        <h2>龙门架</h2>
        <img class="img" src="@/assets/img/龙门架.jpg"/>
      </div>
      <div class="content">
        <h2>效果图</h2>
        <img class="img" src="@/assets/img/视觉检测/效果1.jpg"/>
        <img class="img" src="@/assets/img/视觉检测/效果.jpg"/>
      </div>
    </div>
    <div class="section">
      <h2>视频展示</h2>
      <div class="video">
        <video controls poster="@/assets/videos/定位焊接/视频首页.jpg">
          <source src="@/assets/videos/定位焊接/定位焊接.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue';
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .content {
        margin-top: 10px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>