<template>
  <router-view/>
</template>
<style lang="scss">
  /* 全局样式 */
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    letter-spacing: 1.5px;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    color: #ff4500;
  }
  ul :nth-child(1):hover {
    color: #ff4500;
    cursor: pointer;
  }
  ul :nth-child(2):hover {
    color: #ff4500;
    cursor: pointer;
  }
  ul :nth-child(3):hover {
    color: #ff4500;
    cursor: pointer;
  }
  ul :nth-child(4):hover {
    color: #ff4500;
    cursor: pointer;
  }
  ul :nth-child(5):hover {
    color: #ff4500;
    cursor: pointer;
  }
  ul :nth-child(6):hover {
    color: #ff4500;
    cursor: pointer;
  }
</style>
