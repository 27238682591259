import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login'
import Home from '../views/Home'
import Welding2D from '../views/Welding2D'
import Welding3D from '../views/Welding3D'
import LaserCleaning from '../views/LaserCleaning'
import VisionDetection from '../views/VisionDetection'
import ResistivityDetection from '../views/ResistivityDetection'
import SolutionAerospace from '../views/SolutionAerospace'
import SolutionCar from '../views/SolutionCar'
import SolutionElectron from '../views/SolutionElectron'
import SolutionEnergy from '../views/SolutionEnergy'
import IoT from '../views/IoT'
import Consult from '../views/Consult'
import Software from '../views/Software'
import Online from '../views/Online'
import Feedback from '../views/Feedback'
import About from '../views/About'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/welding/3D',
    name: 'Welding3D',
    component: Welding3D
  },{
    path: '/welding/2D',
    name: 'Welding2D',
    component: Welding2D
  },{
    path: '/laser/cleaning',
    name: 'LaserCleaning',
    component: LaserCleaning
  },{
    path: '/detection/vision',
    name: 'VisionDetection',
    component: VisionDetection
  },{
    path: '/detection/resistivity',
    name: 'ResistivityDetection',
    component: ResistivityDetection
  },{
    path: '/solution/aerospace',
    name: 'SolutionAerospace',
    component: SolutionAerospace
  },{
    path: '/solution/car',
    name: 'SolutionCar',
    component: SolutionCar
  },{
    path: '/solution/electron',
    name: 'SolutionElectron',
    component: SolutionElectron
  },{
    path: '/solution/energy',
    name: 'SolutionEnergy',
    component: SolutionEnergy
  },{
    path: '/IoT',
    name: 'IoT',
    component: IoT
  },{
    path: '/consult',
    name: 'Consult',
    component: Consult
  },{
    path: '/software',
    name: 'Software',
    component: Software
  },{
    path: '/online',
    name: 'Online',
    component: Online
  },{
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },{
    path: '/about',
    name: 'About',
    component: About
  },{
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
