<template>
  <navigate></navigate>
  <div class="main">
    <div v-for="(item,index) in products" :key="index">
      <h3>{{ item.title }}</h3>
      
    </div>
  </div>
  <my-footer></my-footer>
</template>

<script>
  import Navigate from "@/components/Navigate.vue"
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    },
    data() {
      return {
        products: [{
          title: '3D视觉检测系统',
          url: '/detection/vision'
        },{
          title: '3D视觉检测焊接系统',
          url: '/welding/3D'
        },{
          title: '视觉定位焊接系统',
          url: '/welding/2D'
        },{
          title: '激光清洗系统',
          url: '/laser/cleaning'
        },{
          title: '工业物联网平台',
          url: '/IoT'
        },]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main {
    margin-top: 80px;
    padding: 20px 10vw;
  }
</style>