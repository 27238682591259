<template>
  <navigate></navigate>
  <div class="main">
    <h3>最新版本</h3>
  </div>
  <my-footer></my-footer>
</template>

<script>
  import Navigate from "@/components/Navigate.vue"
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    },
  }
</script>

<style lang="scss" scoped>
  .main {
    margin-top: 80px;
    padding: 20px 10vw;
  }
</style>