<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>激光清洗系统</h2>
        <p>该系统龙门桁架XYZ三个自由度，加上机械臂六个自由度，可以达到全方位无死角的清洗效果。</p>
      </div>
      <div class="content">
        <h2>特点</h2>
        <p>非接触式清洗：使用激光束进行清洗，无需与清洗目标物体接触，避免了清洗时对物品造成二次污染、磨损和损伤等问题。</p>
        <p>精度高：激光清洗可精确控制激光束清洗范围和强度，因此可以实现极高的清洗精度。</p>
        <p>自动化程度高：激光清洗系统内置有传感器和自动反馈技术，可以自动调整激光功率和清洗速度，提高了生产效率。</p>
        <p>可用于各种材料：激光清洗系统可清洗多种不同类型的物体表面，如金属、塑料、玻璃等，并且可以清洗不同形状的物体表面，适用范围广泛。</p>
        <p>环保节能：激光清洗无需使用清洗剂或其它化学试剂，且只需要消耗电能进行清洗，因此环保节能。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>系统软件</h2>
        <img class="img" src="@/assets/img/定位焊接/软件.jpg"/>
      </div>
      <div class="content">
        <h2>控制器</h2>
        <img class="img" src="@/assets/img/定位焊接/控制器.jpg"/>
      </div>
      <div class="content">
        <h2>龙门架</h2>
        <img class="img" src="@/assets/img/龙门架.jpg"/>
      </div>
      <div class="content">
        <h2>效果图</h2>
        <img class="img" src="@/assets/img/激光清洗/效果.jpg"/>
      </div>
    </div>
    <div class="section">
      <h2>视频展示</h2>
      <div class="video">
        <video controls poster="@/assets/videos/激光清洗/视频首页.jpg">
          <source src="@/assets/videos/激光清洗/激光清洗.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue'
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .content {
        margin-top: 10px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>