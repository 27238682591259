<template>
  <navigate></navigate>
  <!-- 图片 -->
  <div class="img"></div>
  <!-- 解决方案菜单 -->
  <header>
    <ul>
      <li v-for="(item,index) in solutions" :key="index"
      :style="{color:item.choose?'#fff':'',backgroundColor:item.choose?'#ff4500':''}"
      @click="click(index)">{{ item.name }}</li>
    </ul>
  </header>
  <!-- 内容 -->
  <main>
    <div class="section">
      <div class="solutionImg"></div>
      <div class="content">
        <h2>工业物联网</h2>
        <p>工业物联网是将传感器、设备、计算机等物理设备与互联网融合，形成一种新兴的技术模式，它可以实现设备之间的互联、数据的采集、监测和分析，并对生产过程进行优化和决策支持，从而提高生产效率和产品质量。</p>
      </div>
    </div>
    <div class="product">
      <a href="/IoT">工业物联网平台</a>
    </div>
  </main>
  <my-footer></my-footer>
</template>

<script>
  import Navigate from '@/components/Navigate.vue'
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
        Navigate,
        MyFooter
      },
    data() {
      return {
        solutions: [{ // 领域集合
          name: '制造业',
          choose: false
        },{
          name: '航空航天',
          choose: false
        },{
          name: '电子',
          choose: false
        },{
          name: '工业物联网',
          choose: true
        }]
      }
    },
    methods: {
      click(index) {
        this.solutions.forEach((item)=>{
          item.choose = false
        })
        switch(index) {
          case 0:
            this.$router.push('/solution/car')
            break
          case 1:
            this.$router.push('/solution/aerospace')
            break
          case 2:
            this.$router.push('/solution/electron')
            break
          case 3:
            this.$router.push('/solution/energy')
            break  
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* 图片 */
  .img {
    width: 100%;
    background-image: url(@/assets/img/解决方案/solution.jpg);
  }
  /* 解决方案菜单 */
  header {
    background-color: rgba(128, 128, 128, 0.5);
    ul {
      display: flex;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li:hover {
      color: #fff;
      background-color: #ff4500;
    }
  }
  /* 内容 */
  main {
    margin-left: auto;
    margin-right: auto;
    .content {
      h2 {
        border-bottom: solid 1px #000;
      }
    }
    .solutionImg {
      max-width: 600px;
      max-height: 400px;
      background-image: url(@/assets/img/解决方案/物联网.jpg);
    }
    .product {
      a { 
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px rgba(0,0,0,0.2);
      }
      a:hover {
      color: #fff;
      background-color: #ff4500;
    }
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    /* 图片 */
    .img {
      height: 300px;
      margin-top: 80px;
    }
    /* 解决方案菜单 */
    header {
      li {
        height: 70px;
        margin: 0 10px;
        padding: 0 10px;
        font-size: 20px;
      }
    }
    /* 内容 */
    main {
      width: 80vw;
      margin-top: 50px;
      .section {
        display: flex;
      }
      .content {
        flex: 1;
        padding: 0 50px;
        h2 {
          height: 60px;
        }
        p {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .solutionImg {
        width: 52.5%;
        height: 28vw;
      }
      .product {
        margin-top: 50px;
        a {
          width: 240px;
          height: 60px;
          font-size: 18px;
        }
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    /* 图片 */
    .img {
      height: 160px;
      margin-top: 60px;
    }
    /* 解决方案菜单 */
    header {
      li {
        height: 60px;
        padding: 0 5px;
        margin: 0 3px;
      }
    }
    /* 内容 */
    main {
      width: 90vw;
      margin-top: 20px;
      .content {
        margin-top: 10px;
        h2 {
          height: 40px;
          font-size: 20px;
        }
        p {
          margin-top: 10px;
        }
      }
      .solutionImg {
        width: 100%;
        height: 60vw;
      }
      .product {
        margin-top: 20px;
        a {
          width: 200px;
          height: 40px;
        }
      }
    }
  }
</style>