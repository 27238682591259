<template>
  <navigate></navigate>
  <div class="main">
    <h3>公司简介</h3>
    <p>深圳市和拓创新科技有限公司致力于研发、制造和销售高品质的工业自动化设备。公司成立于2016年，总部位于深圳市，是一家具有高度创新性和前瞻性的公司。我们的产品广泛应用于电子、汽车、新能源等行业，并获得了客户的高度认可和好评。</p>
    <div class="piece">
      <h3>公司产品</h3>
      <p>公司主要产品包括：<a v-for="(item,index) in products" :key="index" :href="item.url">{{ item.title }}</a></p>
    </div>
    <div class="piece">
      <h3>公司愿景</h3>
      <p>我们的愿景是成为全球领先的工业自动化设备制造商，提供最具竞争力的解决方案，为客户创造更大价值。</p>
    </div>
    <div class="piece">
      <h3>公司使命</h3>
      <p>我们的使命是通过不断的技术创新和产品升级，为客户提供最先进的工业自动化设备，帮助客户提高生产效率、降低成本、提升核心竞争力。</p>
    </div>
    <div class="piece">
      <h3>联系我们</h3>
      <p>感谢您对深圳市和拓创新科技有限公司的关注。如果您有任何问题或需求，请联系我们，我们将竭诚为您服务。</p>
    </div>
  </div>
  <my-footer></my-footer>
</template>

<script>
  import Navigate from "@/components/Navigate.vue"
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    },
    data() {
      return {
        products: [{
          title: '3D视觉检测系统、',
          url: '/detection/vision'
        },{
          title: '3D视觉检测焊接系统、',
          url: '/welding/3D'
        },{
          title: '视觉定位焊接系统、',
          url: '/welding/2D'
        },{
          title: '激光清洗系统、',
          url: '/laser/cleaning'
        },{
          title: '工业物联网平台。',
          url: '/IoT'
        },]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main {
    padding: 20px 10vw;
  }
  p {
    margin-top: 5px;
    text-indent: 2em;
  }
  a {
    color: #ff4500;
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      margin-top: 80px;
    }
    p {
      font-size: 18px;
    }
    .piece {
      margin-top: 50px;
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      margin-top: 50px;
    }
    .piece {
      margin-top: 30px;
    }
  }
</style>