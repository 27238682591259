<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>3D视觉检测焊接系统</h2>
        <p>基于AI视觉的焊缝检测焊接系统，本系统由高精度3D相机、视觉检测软件、控制器等构成，可搭配各型主流焊接机器人，采用先检测后焊接的工序对需要焊接的缝隙宽度和高度进行检测，合格的缝隙再焊接，减少由于组装或运输过程中造成的缝隙不合格损失，帮助客户显著提高生产效率，降低成本。</p>
      </div>
      <div class="content">
        <h2>系统特点</h2>
        <p>高精度：系统搭配高精度3D相机结合AI算法实现精度在±0.03mm。</p>
        <p>实用性：传统焊接都是先焊接后检测，无法保证产品合格率。本系统采用先检测后焊接的方式大幅度提高产品合格率，为客户节约成本。</p>
        <p>灵活性：系统不仅可以搭配不同精度的3D相机满足客户对产品的精度要求，还可以根据不同的产品搭配对应的硬件设备，满足客户不同产品的检测焊接需求。</p>
        <p>稳定性：我们会根据客户的实际生产环境采用最合适的材料和方式搭建系统，保证系统稳定性的同时让系统性能最大化。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>系统软件</h2>
        <img class="img" src="@/assets/img/定位焊接/软件.jpg"/>
      </div>
      <div class="content">
        <h2>控制器</h2>
        <img class="img" src="@/assets/img/视觉检测/控制器.jpg"/>
      </div>
      <div class="content">
        <h2>龙门架</h2>
        <img class="img" src="@/assets/img/龙门架.jpg"/>
      </div>
    </div>
    <div class="section">
      <h2>视频展示</h2>
      <div class="video">
        <video controls poster="@/assets/videos/检测焊接/视频首页.jpg">
          <source src="@/assets/videos/检测焊接/检测焊接.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue';
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .frame {
      width: 100%;
      margin-top: 50px;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .content {
        margin-top: 10px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>