<template>
  <footer id="footer">
    <div class="footer-up">
      <p>邮箱：hotway@iqstech.cn</p>
      <p>邮编：518100</p>
      <p>地址：深圳市宝安区福永智荟园A2栋606A</p>
      <p>电话：13723701830</p>
    </div>
    <div class="footer-down">
      <div class="beian">
        <img class="tubiao" src="@/assets/备案图标.png"/>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009811" target="_blank ">粤公网安备 44030502009811号</a>
      </div>
      <a style="padding-left: 25px;" href="https://beian.miit.gov.cn" target="_blank ">粤ICP备2021025466号-3</a>
    </div>
    <p>© 2023 深圳市和拓创新科技有限公司 版权所有</p>
    <div class="scrollToTop">
      <a @click="top"><i class="fas fa-chevron-up"></i></a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
  mounted() {
    /* 回到顶部按钮 */
    const scrollTopEl = document.querySelector(".scrollToTop")
    window.addEventListener("scroll",() =>{
      if (window.pageYOffset !== undefined) {
        if(window.pageYOffset > 500){
          scrollTopEl.style.display = "block";
        }else{
            scrollTopEl.style.display = "none";
        }
      }else {
        if(document.documentElement.scrollTop > 500){
          scrollTopEl.style.display = "block";
        }else{
            scrollTopEl.style.display = "none";
        }
      }
    })
  },
  methods: {
    top() {
      var distance = document.documentElement.scrollTop || document.body.scrollTop
      var step = distance / 30;
      (function jump() {
        if (distance > 0) {
          distance -= step
          window.scrollTo(0, distance)
          setTimeout(jump, 10)
        }
      })()
    }
  }
}
</script>

<style lang="scss" scoped>
  footer {
    margin-top: 50px;
    display: grid;
    justify-items: center;
    background-color: #181818;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #fff;
    font-size: 14px;
    border-top: solid 1px rgba(0,0,0,0.3);
    p {
      padding-top: 10px;
      padding-left: 10px;
    }
  }
  .footer-down {
    padding-top: 50px;
    a {
      color: #e7e9ec;
    }
  }
  .scrollToTop {
    display: none;
    position: relative;
    z-index: 300;
  }

  .scrollToTop a {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff434f;
    color: #fff;
    text-decoration: none;
    position: fixed;
    bottom: 60px;
    right: 30px;
    cursor: pointer;
  }
  /* PC端 */
  @media screen and (min-width: 768px) {
    .footer-up {
      display: flex;
    }
    .footer-down {
      display: flex;
      .tubiao {
        width: 14px
      }
    }
  }
  /* 手机端 */
  @media screen and (max-width: 767px) {
    .footer-down {
      .beian {
        padding-bottom: 10px;
      }
      .tubiao {
        width: 12px
      }
    }
  }
</style>