<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>视觉定位焊接系统</h2>
        <p>视觉定位焊接系统是一种基于计算机视觉技术和自动化控制技术的智能设备，系统由工业相机、视觉定位软件、控制器和振镜等构成，可搭配各型主流焊接机器人，实现复杂焊缝特征提取、轨迹寻位、工件找正定位等功能。可应用于钢结构、车厢板、钢筋、人防门、管板件等零部件的精准定位和高效焊接。</p>
      </div>
      <div class="content">
        <h2>系统特点</h2>
        <p>精准定位：通过视觉识别系统，可以对待焊接工件进行高精度的检测和识别，从而实现较为准确的零部件定位。</p>
        <p>高效焊接：采用自动化控制技术，实现多层次的联动控制，使得焊接过程更加高效，减少了焊接时间和成本。</p>
        <p>多功能性：不仅适用于传统焊接方式，也适用于激光焊接以及其他非接触式焊接方式。</p>
        <p>易操作：通过直观的图形界面和人机交互方式，使得设备的操作更加简单易行。</p>
        <p>良好的稳定性：通过物理学和数学原理的研究，设计出相应的控制算法，并与各种组件进行紧密的集成，确保设备具有良好的稳定性。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>系统软件</h2>
        <img class="img" src="@/assets/img/定位焊接/软件.jpg"/>
      </div>
      <div class="content">
        <h2>控制器</h2>
        <img class="img" src="@/assets/img/定位焊接/控制器.jpg"/>
      </div>
      <div class="content">
        <h2>运动平台</h2>
        <img class="img" src="@/assets/img/定位焊接/产品.jpg"/>
      </div>
      <div class="content">
        <h2>效果图</h2>
        <img class="img" src="@/assets/img/定位焊接/效果.jpg"/>
      </div>
    </div>
    <div class="section">
      <h2>视频展示</h2>
      <div class="video">
        <video controls poster="@/assets/videos/定位焊接/视频首页.jpg">
          <source src="@/assets/videos/定位焊接/定位焊接.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue';
  import MyFooter from "@/components/MyFooter.vue";
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .content {
        margin-top: 10px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>