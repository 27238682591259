<template>
  <navigate></navigate>
  <div class="main">
    <form @submit.prevent="submitPost">
      <div class="form-group">
        <textarea placeholder="描述问题" v-model="form.content" required="required" oninvalid="setCustomValidity('内容不能为空！');" oninput="setCustomValidity('');"></textarea>
      </div>
      <div class="form-group">
        <input placeholder="联系人" v-model="form.name" required="required" oninvalid="setCustomValidity('联系人不能为空！');" oninput="setCustomValidity('');"/>
      </div>
      <div class="form-group">
        <input placeholder="联系方式" v-model="form.contact" required="required" oninvalid="setCustomValidity('联系方式不能为空！');" oninput="setCustomValidity('');"/>
      </div>
      <div class="form-group">
        <input type="submit" value="提交信息"/>
      </div>
    </form>
  </div>
  <my-footer></my-footer>
</template>

<script>
  import Navigate from "@/components/Navigate.vue"
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    },
    data() {
      return {
        form: {
          content: '',
          name: '',
          contact: ''
        }
      }
    },
    methods: {
      submitPost() {
        console.log(this.form)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    background-color: #e2dfdf;
  }
  form {
    background-color: #fff;
  }
  .form-group {
    margin-top: 10px;
    padding-left: 20px;
    input {
      letter-spacing: normal;
      line-height: normal;
    }
  }
  .form-group textarea{
    width: 80%;
    height: 100px;
    letter-spacing: normal;
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    form {
      width: 600px;
      height: 400px;
      margin: 50px 0;
    }
    .form-group textarea{
      height: 200px;
      font-size: 20px;
    }
    input {
      font-size: 20px;
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    form {
      width: 100vw;
      margin-top: 20px;
    }
    .form-group textarea{
      height: 100px;
    }
  }
</style>