import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import './assets/icon/iconfont.css'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jquery from 'jquery'

createApp(App).config.globalProperties.$axios = axios
createApp(App).use(ElementPlus,{locale}).use(store).use(router).use(VueAxios, axios).use(jquery)
.mount('#app')
