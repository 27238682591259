<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>电阻率检测系统</h2>
        <p>产品集成电阻率、温度双探头一体式测量，可广泛用于硅片分选机、生产过程分析等光伏及半导体测量领域。</p>
      </div>
      <div class="content">
        <h2>特点</h2>
        <p>高精度：半导体电阻率的检测精度非常高，可以达到0.1%以内。</p>
        <p>高效性：该系统能够快速准确地测量大量样品，从而提高了生产效率。</p>
        <p>可靠性：该系统采用先进的测试技术和算法，能够排除环境因素对测试结果的影响，从而保证了数据的可靠性。</p>
        <p>易于操作：该系统操作简单易懂，无需过多专业知识，即可完成测试过程。</p>
        <p>显示清晰：配备高清晰液晶显示器，测试结果直观明了，易于判断。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>控制主机</h2>
        <p>可实现电阻率探头、温度探头、光纤信号的统一管理，与远程电脑进行网络通讯。</p>
        <img class="img" src="@/assets/img/电阻率检测/控制主机.jpg"/>
      </div>
      <div class="content">
        <h2>电阻率探头</h2>
        <p>主要利用涡电流测试原理，非接触测试半导体材料，石墨烯，透明导电膜，碳纳米管，金属等材料的电学特性。</p>
        <img class="img" src="@/assets/img/电阻率检测/电阻率探头.jpg"/>
      </div>
      <div class="content">
        <h2>温度探头</h2>
        <p>利用辐射热效应发出的红外辐射能量，进入光学系统，经调制器把红外辐射能量调制为交变辐射，由探测器和电子线路将红外辐射的功率能量转化为电信号。</p>
        <img class="img" src="@/assets/img/电阻率检测/温度探头.jpg"/>
      </div>
    </div>
    <div class="section">
      <h2>效果展示</h2>
      <!-- <div class="video">
        <video controls poster="@/assets/videos/激光清洗/视频首页.png">
          <source src="@/assets/videos/激光清洗/激光清洗.mp4" type="video/mp4">
        </video>
      </div> -->
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue';
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>