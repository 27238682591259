<template>
    <!-- 导航栏 -->
    <Navigate></Navigate>
    <!-- 轮播图 -->
    <div class="swiperbox">
      <swiper
        :slidesPerView="1"
        :loop="true"
        :autoplay="{ delay: 2500, disableOnInteraction: false}"
        :navigation="true"
        :modules="modules">
        <swiper-slide v-for="(item, index) in carousels" :key="index">
          <a :href="item.url">
            <img class="swiperImg" :src="item.bg"/>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <!-- 主体 -->
    <div class="main">
      <div class="section">
        <div class="section-title">
          <h2>关于我们</h2>
        </div>
        <div class="content">
            <div class="content-us">
              <h3>追求卓越品质，提供一流服务，以客户为中心实现共同成长。</h3>
              <p>深圳市和拓创新科技有限公司是一家提供智能制造解决方案的高科技公司，我们的产品包括3D视觉检测系统、3D视觉检测焊接系统、视觉定位焊接系统、激光清洗系统和工业物联网等。通过运用这些产品组合，我们为客户实现了从生产自动化到工厂大数据管理的端到端解决方案。无论您是想优化您的生产线、提高效率还是降低成本，或者想深入了解您产品的性能，我们都可以为您提供最优的解决方案。</p>
            </div>
        </div>
      </div>
      <!-- 应用领域 -->
      <div class="section">
        <div class="section-title">
          <h2>应用领域</h2>
        </div>
        <div class="field">
          <a class="field-img" v-for="(item, index) in fields" :key="index" :href="item.url" :style="{backgroundImage: 'url(' + item.bg + ')'}">
            <div class="streamer">
              <span>{{ item.name }}</span>
              <p>{{ item.text }}</p>
            </div>
          </a>
        </div>
      </div>
      <!-- 成功案例 -->
      <div class="section">
        <div class="section-title">
          <h2>案例展示</h2>
        </div>
        <div class="cases-swiper">
          <swiper :slidesPerView="'auto'">
            <swiper-slide v-for="(item, index) in cases" :key="index">
              <img class="cases-img" :src="item.bg"/>
              <p>{{item.title}}</p>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </template>
  <script>
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Autoplay, Navigation} from 'swiper'
  import 'swiper/css'
  import 'swiper/css/navigation'
  import p1 from "@/assets/img/产品轮播图/视觉检测.jpg"
  import p2 from "@/assets/img/产品轮播图/定位焊接.jpg"
  import p3 from "@/assets/img/产品轮播图/激光清洗.jpg"
  import p4 from "@/assets/img/产品轮播图/物联网平台.jpg"
  import f1 from "@/assets/img/应用领域/制造业.jpg"
  import f2 from "@/assets/img/应用领域/航空航天.jpg"
  import f3 from "@/assets/img/应用领域/激光清洗.jpg"
  import f4 from "@/assets/img/应用领域/工业物联网.jpg"
  import c1 from "@/assets/img/检测焊接/产品.jpg"
  import c2 from "@/assets/img/激光清洗/产品.jpg"
  import c3 from "@/assets/img/工业物联网/IoT.jpg"
  import Navigate from "@/components/Navigate.vue"
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        modules: [Autoplay, Navigation],
        carousels: [{ //轮播图
          bg: p1,
          url: '/detection/vision'
        },
        {
          bg: p2,
          url: '/welding/2D'
        },
        {
          bg: p3,
          url: '/laser/cleaning'
        },
        {
          bg: p4,
          url: '/IoT'
        }],
        fields: [{
          name: '工业质量检测',
          bg: f1,
          url: '/solution/car',
          text: '3D视觉检测系统可以确保生产出来的产品能够符合规定的质量标准和客户的要求。'
        },
        {
          name: '焊接工序',
          bg: f2,
          url: '/solution/aerospace',
          text: '3D视觉检测焊接系统在航空航天领域中发挥了重要的作用，不仅能够提高焊接的质量和效率，还可以减小误差，降低成本。'
        },
        {
          name: '产品清洗',
          bg: f3,
          url: '/solution/electron',
          text: '激光清洗具有高精度、高效率、环保节能的特点，在航空、汽车、轨道交通、电子、卫星等领域得到了广泛应用。'
          },
        {
          name: '工业物联网',
          bg: f4,
          url: '/solution/energy',
          text: '工业物联网技术十分广泛且具有前瞻性，将在提高工业生产效率、优化生产流程、降低成本、促进资源可持续利用等方面发挥越来越重要的作用。'
        }],
        cases: [{
          title: '3D视觉检测焊接',
          bg: c1
        },
        {
          title: '激光清洗',
          bg: c2
        },
        {
          title: '充电桩监控',
          bg: c3
        }]
      }
    },
    methods: {
      //滚动到指定位置
      toLocal(loc) {
        let toElement = document.getElementById(loc)
        toElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
    /* 轮播图 */
    .swiperbox{
      width: 90vw;
      height: 45vw;
      max-width: 1344px;
      max-height: 672px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper{
      --swiper-navigation-color: #000;
    }
    .swiperImg {
      height: 100%;
      width: 100%;
    }
    .cases-swiper {
      display: flex;
      .swiper {
        margin-left: 0;
      }
      .swiper-slide{
        width: auto;/*根据内容调整宽度*/
        text-align: center;
        margin: 0 10px;
      }

    }
    /* 主体 */
    .main {
      width: 84vw;
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
    }
    /* 图片 */
    .field {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .field-img {
        width: 36vw;
        height: 24vw;
        border: solid 5px #fff;
        position: relative;
        max-width: 480px;
        max-height: 320px;
        .streamer {
          height: 60px;
          width: 100%;
          position: absolute;
          bottom: 0;
          background-color: rgba(0,0,0,0.2);
          transition: all 0.5s ease;
          span {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
          }
          p {
            display: none;
            width: 90%;
            padding-top: 5px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .field-img:hover .streamer{
        height: 160px;
        color: #fff;
        background-color: #ff4500;
        p {
          display: -webkit-box;
          font-size: 16px;
          border-top: solid 1px #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
    /* PC端 */
    @media screen and (min-width: 992px) {
      /* 轮播图 */
      .swiperbox {
        margin-top: 80px;
      }
      /* 成功案例 */
      .cases-swiper {
        height: 280px;
        font-size: 18px;
        .cases-img {
          height: 250px;
        }
      }
      /* 主体 */
      .main {
        /* 板块间隔 */
        .section {
          padding-top: 40px;
        }
        .section-title {
          font-size: 20px;
        }
        /* 介绍 */
        .content {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
        }
      }
    }
    /* 手机平板端 */
    @media screen and (max-width: 991px) {
      /* 轮播图 */
      .swiperbox {
        margin-top: 60px;
      }
      .swiper{
        --swiper-navigation-size: 26px;
      }
      .main {
        /* 板块间隔 */
        .section {
          padding-top: 20px;
        }
      }
      /* 图片 */
      .field {
        .field-img {
          width: 100%;
          height: 56vw;
          .streamer {
            height: 40px;
            span { 
              font-size: 16px;
              height: 40px;
            }
          }
        }
        .field-img:hover .streamer{
          height: 130px;
          p {
            font-size: 14px;
          }
        }
      }
    }
    /* 平板端 */
    @media screen and (min-width: 768px) and (max-width: 991px) {
      /* 主体 */
      .main {
        .section-title {
          font-size: 16px;
        }
      }
      /* 成功案例 */
      .cases-swiper {
        height: 200px;
        font-size: 16px;
        .cases-img {
          height: 170px;
        }
      }
    }
    /* 手机端 */
    @media screen and (max-width: 768px) {
      /* 主体 */
      .main {
        /* 板块间隔 */
        .section-title {
          font-size: 14px;
        }
      }
      /* 成功案例 */
      .cases-swiper {
        height: 130px;
        font-size: 14px;
        .cases-img {
          height: 100px;
        }
      }
    }
</style>