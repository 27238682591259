<template>
  <!-- 遮挡层 -->
  <div v-if="example" class="example" @click="guan"></div>
  <!-- 右侧导航菜单 -->
  <div class="sm-nav" ref="sm-nav">
    <div class="close"><span @click="close">×</span></div>
    <ul class="shang">
			<li @click="select">
				<a href="/">首页</a>
			</li>
			<li @click="select1">
				<span>产品</span>
        <h2>+</h2>
			</li>
			<li @click="select2">
				<span>解决方案</span>
        <h2>+</h2>
			</li>
			<li @click="select3">
				<span>服务与支持</span>
        <h2>+</h2>
			</li>
			<!-- <li @click="select4">
				<span>关于我们</span>
			</li> -->
		</ul>
    <ul>
      <li class="xia-link">
				<a href="/about">关于我们</a>
			</li>
      <li class="xia-link">
        <a @click="toLocal('footer')">联系我们</a>
      </li>
    </ul>
  </div>
  <!-- 右侧产品 -->
  <div class="sm-menuBar" ref="sm-product">
    <div class="back">
      <i class="left" @click="back1"></i>
      <h3 @click="back1">产品</h3>
    </div>
    <div class="sm-product-box">
      <h3>检测系统</h3>
      <div class="product-name" v-for="(item,index) in detections" :key="index">
        <a :href="item.url">{{item.name}}</a>
      </div>
    </div>
    <div class="sm-product-box">
      <h3>焊接系统</h3>
      <div class="product-name" v-for="(item,index) in weldings" :key="index">
        <a :href="item.url">{{item.name}}</a>
      </div>
    </div>
    <div class="sm-product-box">
      <h3>清洗系统</h3>
      <div class="product-name" v-for="(item,index) in washs" :key="index">
        <a :href="item.url">{{item.name}}</a>
      </div>
    </div>
    <div class="sm-product-box">
      <h3>工业物联网</h3>
      <div class="product-name" v-for="(item,index) in IoTs" :key="index">
        <a :href="item.url">{{item.name}}</a>
      </div>
    </div>
  </div>
  <!-- 右侧解决方案 -->
  <div class="sm-menuBar" ref="sm-solution">
    <div class="back">
      <i class="left" @click="back2"></i>
      <h3 @click="back2">解决方案</h3>
    </div>
    <div class="sm-menuBar-up">
      <a href="/solution/car">制造业</a>
    </div>
    <div class="sm-menuBar-up">
      <a href="/solution/aerospace">航空航天</a>
    </div>
    <div class="sm-menuBar-up">
      <a href="/solution/electron">电子</a>
    </div>
    <div class="sm-menuBar-up">
      <a href="/solution/energy">工业物联网</a>
    </div>
  </div>
  <!-- 右侧服务和支持 -->
  <div class="sm-menuBar" ref="sm-serve">
    <div class="back">
      <i class="left" @click="back3"></i>
      <h3 @click="back3">服务与支持</h3>
    </div>
    <!-- <div class="sm-menuBar-up">
      <a href="/consult">产品咨询</a>
    </div> -->
    <div class="sm-menuBar-up">
      <a href="/software">软件升级</a>
    </div>
    <!-- <div class="sm-menuBar-up">
      <a href="/">意见建议</a>
    </div> -->
    <div class="sm-menuBar-up">
      <a href="/feedback">问题反馈</a>
    </div>
    <!-- <div class="sm-menuBar-up">
      <a href="/">合作加盟</a>
    </div>
    <div class="sm-menuBar-up">
      <a href="/">意见建议</a>
    </div> -->
  </div>
  <!-- 右侧关于我们 -->
  <div class="sm-menuBar" ref="sm-us">
    <div class="back">
      <i class="left" @click="back4"></i>
      <h3 @click="back4">关于我们</h3>
    </div>
    <div class="sm-menuBar-up">
      <a href="/about">公司简介</a>
    </div>
    <!-- <div class="sm-menuBar-up">
      <a href="/">合作加盟</a>
    </div> -->
    <!-- <div class="sm-menuBar-up">
      <a href="/">公司动态</a>
    </div>
    <div class="sm-menuBar-up">
      <a @click="toLocal('footer')">联系我们</a>
    </div> -->
  </div>
  <!-- 导航 -->
  <header>
    <div class="logo">Hotway</div>
    <div class="menu">
      <a class="menu-item" href="/">首页</a>
      <a class="menu-item">产品</a>
      <a class="menu-item">解决方案</a>
      <a class="menu-item">服务与支持</a>
      <a class="menu-item" href="/about">关于我们</a>
      <!-- 产品 -->
      <div class="menuBar" id="product">
        <div class="product-box">
          <h3>检测系统</h3>
          <div class="product-name" v-for="(item,index) in detections" :key="index">
            <a :href="item.url">{{item.name}}</a>
          </div>
        </div>
        <div class="product-box">
          <h3>焊接系统</h3>
          <div class="product-name" v-for="(item,index) in weldings" :key="index">
            <a :href="item.url">{{item.name}}</a>
          </div>
        </div>
        <div class="product-box">
          <h3>清洗系统</h3>
          <div class="product-name" v-for="(item,index) in washs" :key="index">
            <a :href="item.url">{{item.name}}</a>
          </div>
        </div>
        <div class="product-box">
          <h3>工业物联网</h3>
          <div class="product-name" v-for="(item,index) in IoTs" :key="index">
            <a :href="item.url">{{item.name}}</a>
          </div>
        </div>
        <!-- <div v-if="products[0].choose" class="menuBar-right">
          <div class="product-box">
            <a href="/welding/3D">3D视觉检测焊接系统</a>
          </div>
          <div class="product-box">
            <a href="/welding/2D">视觉定位焊接系统</a>
          </div>
        </div>
        <div v-if="products[1].choose" class="menuBar-right">
          <div class="product-box">
            <a href="/laser/cleaning">激光清洗系统</a>
          </div>
        </div>
        <div v-if="products[2].choose" class="menuBar-right">
          <div class="product-box">
            <a href="/detection/vision">3D视觉检测系统</a>
          </div>
          <div class="product-box">
            <a href="/detection/resistivity">电阻率检测系统</a>
          </div>
        </div>
        <div v-if="products[3].choose" class="menuBar-right">
          <div class="product-box">
            <a href="/IoT">物联网平台</a>
          </div>
        </div> -->
      </div>
      <!-- 解决方案 -->
      <div class="menuBar" id="solution">
        <div class="menuBar-solution">
          <a href="/solution/car">制造业&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;></a>
          <a href="/solution/electron">电子&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;></a>
        </div>
        <div class="menuBar-solution">
          <a href="/solution/aerospace">航空航天&nbsp;&nbsp;&nbsp;&nbsp;></a>
          <a href="/solution/energy">工业物联网&nbsp;&nbsp;></a>
        </div>
      </div>
      <!-- 服务和支持 -->
      <div class="menuBar" id="serve">
        <div class="menuBar-solution">
          <!-- <a href="/online">产品咨询&nbsp;&nbsp;&nbsp;&nbsp;></a> -->
          <a href="/software">软件升级&nbsp;&nbsp;&nbsp;&nbsp;></a>
          <!-- <a href="/">软件升级&nbsp;&nbsp;&nbsp;&nbsp;></a> -->
        </div>
        <div class="menuBar-solution">
          <!-- <a href="/">意见建议&nbsp;&nbsp;&nbsp;&nbsp;></a> -->
          <a href="/feedback">问题反馈&nbsp;&nbsp;&nbsp;&nbsp;></a>
        </div>
      </div>
      <!-- 关于我们 -->
      <!-- <div class="menuBar" id="us">
        <div class="menuBar-solution">
          <a href="/about">公司简介&nbsp;&nbsp;&nbsp;&nbsp;></a>
          <a href="/">历史&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;></a>
        </div>
        <div class="menuBar-solution">
          <a href="/">合作加盟&nbsp;&nbsp;&nbsp;&nbsp;></a>
          <a @click="toLocal('footer')">联系我们&nbsp;&nbsp;&nbsp;&nbsp;></a>
        </div>
      </div> -->
    </div>
    <ul class="header-links">
      <li class="header-link">
        <a @click="toLocal('footer')">联系我们</a>
      </li>
      <div class="menu-icon" @click="open"></div>
    </ul>
  </header>
</template>

<script>
export default {
  name: "Navigate",
  data() {
    return {
      example: false,
      detections: [{ // 检测
        name: '3D视觉检测系统',
        url: '/detection/vision'
      }],
      weldings: [{ // 焊接
        name: '3D视觉检测焊接系统',
        url: '/welding/3D'
      },{
        name: '视觉定位焊接系统',
        url: '/welding/2D'
      }],
      washs: [{ // 清洗
        name: '激光清洗系统',
        url: '/laser/cleaning'
      }],
      IoTs: [{ // 物联网
        name: '工业物联网平台',
        url: '/IoT'
      }],
    }
  },
  methods: {
    /* 打开导航栏 */
    open() {
      this.example = true
      this.$refs['sm-nav'].style.right = '0'
    },
    /* 关闭导航栏 */
    close() {
      this.example = false
      this.$refs['sm-nav'].style.right = '-600px'
    },
    /* 右侧菜单选择 */
    /* 首页 */
    select() {
      this.$router.push('/')
    },
    /* 产品 */
    select1() {
      this.$refs['sm-product'].style.right = '0'
    },
    back1() {
      this.$refs['sm-product'].style.right = '-300px'
    },
    /* 解决方案 */
    select2() {
      this.$refs['sm-solution'].style.right = '0'
    },
    back2() {
      this.$refs['sm-solution'].style.right = '-300px'
    },
    /* 服务和支持 */
    select3() {
      this.$refs['sm-serve'].style.right = '0'
    },
    back3() {
      this.$refs['sm-serve'].style.right = '-300px'
    },
    /* 关于我们 */
    select4() {
      this.$refs['sm-us'].style.right = '0'
    },
    back4() {
      this.$refs['sm-us'].style.right = '-300px'
    },
    /* 点击遮挡层 */
    guan() {
      this.example = false
      this.$refs['sm-nav'].style.right = '-300px'
      this.$refs['sm-product'].style.right = '-300px'
      this.$refs['sm-solution'].style.right = '-300px'
      this.$refs['sm-serve'].style.right = '-300px'
      this.$refs['sm-us'].style.right = '-300px'
    },
    //滚动到指定位置
    toLocal(loc) {
      let toElement = document.getElementById(loc)
      if (document.documentElement.clientWidth < 992) {
        this.example = false
        this.$refs['sm-nav'].style.right = '-300px'
        this.$refs['sm-us'].style.right = '-300px'
      }
      toElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 遮挡层 */
  .example {
		position: fixed;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
    top: 0;
		background: rgba(0,0,0,0.3);
		z-index: 11;
	}
  /* 导航 */
  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    padding-left: 4vw;
    padding-right: 4vw;
    font-weight: 600;
    z-index: 12;
    background-color: #fff;
    font-family: '黑体';
    /* 菜单 */
    .menu {
      height: 100%;
      display: flex;
      align-items: center;
      .menu-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        cursor: pointer;
      }
    }
    span:hover {
      color: #ff4500;
    }
    .menu :nth-child(2):hover ~ #product {
      top: 80px;
      animation: pullDown 0.5s ease;
    }
    .menu :nth-child(3):hover ~ #solution {
      top: 80px;
      animation: pullDown 0.5s ease;
    }
    .menu :nth-child(4):hover ~ #serve {
      top: 80px;
      animation: pullDown 0.5s ease;
    }
    #product:hover {
      top: 80px;
    }
    #solution:hover {
      top: 80px;
    }
    #serve:hover {
      top: 80px;
    }
    .header-links {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-link {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /* 右侧导航图标 */
    .menu-icon {
      position: absolute;
      right: 2rem;
      width: 2rem;
      height: 24px;
      padding: .3rem 0;
      background-color: #000;
      border-top: .3rem solid #000;
      border-bottom: .3rem solid #000;
      background-clip: content-box;
    }
      /* 菜单栏 */
    .menuBar {
      width: 100%;
      height: 300px;
      position: fixed;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 15vw;
      top: -300px;
      left: 0;
      background-color: #f0ebeb;
      font-size: 16px;
      font-weight: normal;
      cursor: default;
      .product-box {
        width: 200px;
        margin-right: 40px;
        h3 {
          padding-bottom: 5px;
          color: #ff4500;
          border-bottom: solid 1px rgba(0,0,0,0.3);
        }
        .product-name {
          margin-top: 5px;
        }
      }
    }
    /* 解决方案 */
    .menuBar-solution {
      a {
        width: 160px;
        height: 40px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
      }
      a:hover {
        background-color: #fff;
      }
    }
  }
  @keyframes pullDown {
    from {
      height: 0;
    }
    to {
      height: 300px;
    }
  }
  /* 右侧导航 */
  .sm-nav {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    padding: 20px 40px;
    list-style: none;
    background-color: #fff;
    transition: all 0.5s ease;
    z-index: 15;
    cursor: default;
    li {
      width: 100%;
      font-size: 18px;
    }
    .shang li {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px rgba(0,0,0,0.3);
    }
    .xia-link {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    .close {
      position: absolute;
      right: 40px;
      font-size: 40px;
    }
  }
  /* 右侧产品 */
  .sm-menuBar {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    padding: 20px 40px;
    list-style: none;
    background-color: #fff;
    transition: all 0.5s ease;
    z-index: 15;
    overflow-y: scroll;
    cursor: default;
    .back {
      // height: 60px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px rgba(0,0,0,0.3);
      /* 左箭头 */
      .left {
        margin-right: 5px;
        height: 12px;
        width: 12px;
        border-left: 3px solid black;
        border-bottom: 3px solid black;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
    .sm-menuBar-up {
      padding: 20px 0;
      border-bottom: solid 1px rgba(0,0,0,0.3);
      p {
        margin-top: 10px;
      }
    }
    .sm-product-box {
      width: 200px;
      margin-right: 40px;
      margin-top: 20px;
      h3 {
        padding-bottom: 5px;
        color: #ff4500;
        border-bottom: solid 1px rgba(0,0,0,0.3);
      }
      .product-name {
        margin-top: 5px;
      }
    }
  }
  /* 超大屏PC端 */
  // @media screen and (min-width: 1600px) {

  // }
  // /* 大屏PC端 */
  // @media screen and (min-width: 1200px) and (max-width: 1599px) {

  // }
  // /* 小屏PC端 */
  // @media screen and (min-width: 992px)  and (max-width: 1199px) {

  // }
  /* PC端 */
  @media screen and (min-width: 992px) {
    header {
      height: 80px;
      font-size: 22px;
    }
    /* 隐藏右侧导航图标 */
    .menu-icon {
      display: none;
    }
    .sm-nav {
      display: none;
    }
    .sm-menuBar {
      display: none;
    }
  }
  /* 手机平板端 */
  @media screen and (max-width: 991px) {
    header {
      height: 60px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      /* 隐藏导航菜单 */
      .menu {
        display: none;
      }
      .header-link {
        display: none;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    header {
      padding: 0 24px;
      font-size: 22px;
    }
  }
  /* 手机端 */
  @media screen and (max-width: 768px) {
    header {
      padding: 0 20px;
      font-size: 18px;
    }
  }
</style>