<template>
  <navigate></navigate>
  <div class="main">
    <div class="section">
      <div class="content">
        <h2>工业物联网平台</h2>
        <p>工业物联网平台采用多层ID管理和鉴权机制，能够有效的管理开发者/应用/设备/用户的ID。通过合理的数据库，负载均衡模块以及数据管理模块的协同运作，可以实现海量化，多样化的设备接入，同时支持无线、有线、企业级接入、支持各种主流IoT网关接入。它可以为工业制造、运输、生产等行业带来更高效、更安全和更节省成本的解决方案。</p>
      </div>
      <div class="content">
        <h2>平台组件</h2>
        <p>设备连接及数据采集：工业物联网平台通过传感器和其他设备与现有系统进行交互，从而实现将设备转换为数字格式并收集大量数据的过程。</p>
        <p>数据处理和分析：工业物联网平台基于收集到的数据提供先进的数据处理和分析功能，例如数据挖掘、预测性分析和实时仪表盘等。这些功能可以帮助企业快速获得洞察力，并支持更好地决策。</p>
        <p>云存储和集成：工业物联网平台使用云技术来存储和管理大量数据，并提供对各种应用程序和系统的无缝集成。</p>
        <p>安全和合规性：工业物联网平台需要确保所有连接的设备和用户数据的安全，同时遵守符合法规和合规性要求。</p>
      </div>
      <div class="content">
        <h2>平台特点</h2>
        <p>实时监测和响应：工业物联网平台可以不间断地监测设备状态和环境数据，还能够自动发送警报并执行响应活动以减少潜在风险。</p>
        <p>高效生产和维护：工业物联网平台使用数据和分析可优化生产和维护过程，提高生产率、减少停机时间和延长设备寿命</p>
        <p>自动化和自适应：工业物联网平台可以基于数据和模型进行预测性分析，以实现自动化和智能做出调整，使操作更加自适应。</p>
        <p>灵活和可扩展：工业物联网平台具备灵活性和可扩展性，因而可以轻松满足各种规模和需求。</p>
        <p>降低成本和资源消耗：工业物联网平台可以帮助企业从多个方面节省资源和成本，例如能源效率、设备生命周期管理、人力资源等。</p>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <h2>平台展示</h2>
        <img class="img" src="@/assets/img/工业物联网/IoT.jpg"/>
      </div>
    </div>
  </div>
  <!-- 底部 -->
  <my-footer></my-footer>
</template>
<script>
  import Navigate from '@/components/Navigate.vue'
  import MyFooter from "@/components/MyFooter.vue"
  export default {
    components: {
      Navigate,
      MyFooter
    }
  }
</script>
<style lang="scss" scoped>
  //主体
  .main {
    width: 100%;
    .section {
      width: 100%;
    }
    .img {
      max-width: 650px;
      max-height: 400px;
    }
    video {
      max-width: 600px;
      max-height: 480px;
      object-fit: cover;
    }
  }
  /* PC端 */
  @media screen and (min-width: 992px) {
    .main {
      padding-top: 110px;
      padding-left: 10vw;
      padding-right: 10vw;
      font-size: 18px;
      .section {
        margin-top: 40px;
      }
      .content {
        margin-top: 20px;
      }
      .img {
        width: 50%;
      }
      .video {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      video {
        width: 50%;
        height: 35vh;
        padding: 20px 5px;
      }
    }
  }
  /* 平板手机端 */
  @media screen and (max-width: 991px) {
    .main {
      padding-top: 80px;
      padding-left: 6vw;
      padding-right: 6vw;
      .section {
        margin-top: 20px;
      }
      .content {
        margin-top: 10px;
      }
      .img {
        width: 100%;
      }
    }
  }
  /* 平板端 */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    video {
      width: 50%;
      height: 30vw;
      padding: 10px 5px;
    }
  }
  /* 手机端 */
  @media (max-width: 768px) {
    video {
      width: 100%;
      height: 50vw;
      margin-top: 10px;
    }
  }
</style>